import React from 'react';
import { Link } from 'react-router-dom';

export default ({
    auth,
    authToken
}) => (
    <div className="header">
        <div style={{
            background: "rgb(20, 20, 20)",
            padding: '15px' }}>
            
                <Link to="/">
                    <img style={{
                        width: 100,
                        paddingTop: 10
                    }} 
                    src="https://i0.wp.com/minhastack.com/wp-content/uploads/2021/01/logoVerticalVazada.png?fit=2100%2C700&ssl=1" />
                </Link>

                {(auth && authToken) ? (
                    <>
                        <div className="perfil" style={{background: "rgba(255, 255, 255, 0.8)"}}>
                            <div className="name">{auth.currentUser.displayName}</div>
                            <div className="button" onClick={() => auth.signOut()}>Sair</div> 
                        </div>
                    </>
                ) : null }
        </div>
    </div>
)