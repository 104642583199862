import React, { useEffect, useState } from 'react';

export default function Example ({
    auth,
    authToken
}) {
    return (
        <div>
            <div className="container insight-basic-padding">
                Página de exemplo para validação do 
                <br />
                
            </div>
        </div>
    )
}