import React, { useEffect, useState } from 'react';
import { newPermission } from './service';
import { acceptPermission } from './service';
import { readWaitingPermissions } from './service';
import { readActivePermissions } from './service';

export default function Permissions ({
    auth,
    authToken
}) { 
    const [waitingPermissions, setWaitingPermissiosn] = useState({ loading: true, data: []});
    const [activePermissions, setActivePermissions] = useState({ loading: true, data: []});
    
    const loadPermissions = async () => {
        setWaitingPermissiosn({ loading: false, data: await readWaitingPermissions({ authToken }) });
        setActivePermissions({ loading: false, data: await readActivePermissions({ authToken }) });
    }
    
    useEffect(() => {
        loadPermissions();
    }, [])

    return (
        <div>
            <div className="container insight-basic-padding">
                <div className="button-light sm" onClick={() => {newPermission({ authToken })}}>Adicionar nova permissão personalizada</div>
                <div className="insight">Permissões em análise</div>
            </div>
            <div className="container insight-basic-padding">
                {!waitingPermissions.loading && waitingPermissions.data.length === 0 && ( <h3>Nenhuma permissão localizado.</h3> )}
                {waitingPermissions.loading ? ( <h3>Carregando...</h3> ) : waitingPermissions.data.map(({ name, userProvidedData, _id }) => (
                    <div>
                        <div className="button-light sm" onClick={() => {acceptPermission({ authToken ,_id })}}>Aceitar</div>
                        <div className="insight">{name} - {userProvidedData} - {_id}</div>
                    </div>
                ))}
            </div>
            <div className="container insight-basic-padding">
                <div className="insight">Permissões aceitas</div>
            </div>
            
            <div className="container insight-basic-padding">
                {!activePermissions.loading && activePermissions.data.length === 0 && ( <h3>Nenhum produto localizado.</h3> )}
                {activePermissions.loading ? ( <h3>Carregando...</h3> ) : activePermissions.data.map(({ name, userProvidedData, _id }) => (
                    <div>
                        <div className="button-light sm" onClick={() => {}}>Revogar</div>
                        <div className="insight">{name} - {userProvidedData} - {_id}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}