const API_ENDPOINTS = "https://ms0001.herokuapp.com";

const dataObject = async (fetchElement) => {
    return (await fetchElement.json()).data;
}

export async function readMyProducts ({ authToken }) {
    return dataObject( await fetch(API_ENDPOINTS+ "/products/mine", {
        method: 'GET',
        headers: {
            'Authorization': authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }));
}

export async function readOtherProducts ({ authToken }) {
    return dataObject(await fetch(API_ENDPOINTS+ "/products/others", {
        method: 'GET',
        headers: {
            'Authorization': authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }));
}

export async function newModule({ authToken }){
    let name = prompt("Nome do módulo")
    let description = prompt("Descrição do módulo")
    let integrationLink = prompt("Link de integração do módulo")
    let paymentValue = prompt("Preço do módulo")

    let create = await fetch(API_ENDPOINTS+ "/products", {
        method: 'POST',
        body: JSON.stringify({
            name,
            type: "PRODUCT",
            description,
            integrationLink,
            paymentValue,
            paymentType: "PRODUCT",
        }),
        headers: {
            'Authorization': authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
}

export async function requestAccess({ authToken, _id }){
    alert("Faça um pix para minhastack@gmail.com no valor de 30 reais.");
    let userProvidedData = prompt("Identificação do pagador do pix")

    let create = await fetch(API_ENDPOINTS+ "/permissions/request", {
        method: 'POST',
        body: JSON.stringify({
            productId: _id,
            userProvidedData
        }),
        headers: {
            'Authorization': authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
}

export async function redirectToProduct({ authToken, _id, integrationLink }){
    let redirectData = await fetch(API_ENDPOINTS+ "/products/access", {
        method: 'POST',
        body: JSON.stringify({
            productId: _id,
        }),
        headers: {
            'Authorization': authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });

    const returnedData = (await redirectData.json())
    let msToken = returnedData._id;

    let newWindow = window.open(integrationLink + "?mstoken=" + msToken)
    newWindow.focus()
}
