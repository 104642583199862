import React, { useEffect, useState } from 'react';
import { newModule, readMyProducts, readOtherProducts, redirectToProduct, requestAccess } from './service';

export default function Modules ({
    auth,
    authToken
}) { 
    const [myProducts, setMyProducts] = useState({ loading: true, data: []});
    const [otherProducts, setOtherProducts] = useState({ loading: true, data: []});
    
    const loadProducts = async () => {
        setMyProducts({ loading: false, data: await readMyProducts({ authToken }) });
        setOtherProducts({ loading: false, data: await readOtherProducts({ authToken }) });
    }
    
    useEffect(() => {
        loadProducts();
    }, [])

    const Product = ({ paymentValue, description, name, _id, toolbar, integrationLink }) => (
        <div className='productItem'>
            {toolbar({ authToken, _id, integrationLink })}
            <div className="small">R$ {paymentValue} - {_id}</div>
            <div className="title">{name}</div>
            <div className="normal">{description}</div>
        </div>
    );

    return (
        <div>
            <div className="container insight-basic-padding">
                <div className="button-light sm" onClick={() => {newModule({ authToken })}}>Adicionar novo módulo</div>
                <div className="insight">Meus Módulos</div>
            </div>
            <div className="container insight-basic-padding">
                {!myProducts.loading && myProducts.data.length === 0 && ( <h3>Nenhum produto localizado.</h3> )}
                {myProducts.loading ? ( <h3>Carregando...</h3> ) : myProducts.data.map((product) => <Product {...product} toolbar={({ _id, integrationLink }) => (
                    <div className="simpleButton" onClick={() => {redirectToProduct({ authToken, _id, integrationLink })}}>Acessar - {integrationLink}</div>
                )} /> )}
            </div>
            <div className="container insight-basic-padding">
                <div className="insight">Outros Módulos</div>
            </div>
            
            <div className="container insight-basic-padding">
                {!otherProducts.loading && otherProducts.data.length === 0 && ( <h3>Nenhum produto localizado.</h3> )}
                {otherProducts.loading ? ( <h3>Carregando...</h3> ) : otherProducts.data.map((product) => <Product {...product}  toolbar={({ _id }) => (
                    <div className="simpleButton" onClick={() => {requestAccess({ authToken ,_id })}}>Requisitar acesso</div>
                )}  /> )}
            </div>
        </div>
    )
}