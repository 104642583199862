const API_ENDPOINTS = "https://ms0001.herokuapp.com";

const dataObject = async (fetchElement) => {
    return (await fetchElement.json()).data;
}

export async function readWaitingPermissions ({ authToken }) {
    return dataObject( await fetch(API_ENDPOINTS+ "/permissions/waiting", {
        method: 'GET',
        headers: {
            'Authorization': authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }));
}

export async function readActivePermissions ({ authToken }) {
    return dataObject(await fetch(API_ENDPOINTS+ "/permissions/active", {
        method: 'GET',
        headers: {
            'Authorization': authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }));
}

export async function newPermission({ authToken }){
    let user = prompt("Descreva o ID de usuário da permissão")
    let name = prompt("Descreva o Nome da permissão")
    let type = prompt("Descreva o Tipo da permissão")
    let description = prompt("Descreva a Descrição da permissão")
    let waiting = prompt("Descreva se a permissão está pendente")
    let active = prompt("Descreva se a permissão está ativa")
    
    let create = await fetch(API_ENDPOINTS+ "/products", {
        method: 'POST',
        body: JSON.stringify({
            user,
            name,
            type,
            description,
            waiting,
            active,
        }),
        headers: {
            'Authorization': authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
}

export async function acceptPermission({ authToken, _id }){
    let create = await fetch(API_ENDPOINTS+ "/permissions/accept", {
        method: 'POST',
        body: JSON.stringify({
            permissionId: _id
        }),
        headers: {
            'Authorization': authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
}
