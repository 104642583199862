import React from 'react';

import {
    Route,
    Routes,
    HashRouter
} from "react-router-dom";

import Header from './templates/Header';

import Home from './modules/Home';
import Task from './modules/Task';
import Subscription from './modules/Subscription';
import Modules from './modules/Modules';
import Permissions from './modules/Permissions';
import Example from './modules/Example';

export default function AppHandler ({
    auth,
    authToken
}) {
    return (
        <HashRouter>
            <div>
                <Header auth={auth} authToken={authToken} />
                <Routes>
                    <Route path="/" element={<Home auth={auth} authToken={authToken}  />} />
                    <Route path="/task" element={<Task auth={auth} authToken={authToken}  />} />
                    <Route path="/subscription" element={<Subscription auth={auth} authToken={authToken}  />} />
                    <Route path="/permissions" element={<Permissions auth={auth} authToken={authToken}  />} />
                    <Route path="/modules" element={<Modules auth={auth} authToken={authToken}  />} />
                    <Route path="/example" element={<Example auth={auth} authToken={authToken}  />} />
                </Routes>
            </div>
        </HashRouter>
    )
}