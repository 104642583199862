import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

export default function Home ({
    auth,
    authToken
}) { 
    return (
        <div>
            <div className="bigTitle">
                <div className='heading'>Bem vindo ao portal MinhaStack</div>
                <div className='description'>Encontre todos os serviços e sistemas que oferecemos em um único local</div>
            </div>

            <div className="division">
                <div className='heading'>Alguns projetos que desenvolvemos que podem ser relevantes</div>
                <div className='interactButton'><a href="https://github.com/minhastack/discord-bot-js" target="_blank">Bot de Vagas do Discord escrito em Node.js</a></div>
                <div className='interactButton'><a href="https://github.com/minhastack/play.minhastack.com" target="_blank">Play Stack - Site com serviços simples em Node.js</a></div>
            </div>

            <div className="division">
                <div className='heading'>Interaja com a comunidade</div>
                <div className='interactButton'><a href="https://discord.minhastack.com/" target="_blank">Discord</a></div>
                <div className='interactButton'><a href="https://shopee.com/minhastack" target="_blank">Shopee</a></div>
                <div className='interactButton'><a href="https://www.instagram.com/minhastack/" target="_blank">Instagram</a></div>
                <div className='interactButton'><a href="https://www.tiktok.com/@minhastack" target="_blank">TikTok</a></div>
                <div className='interactButton'><a href="https://www.youtube.com/channel/UCcHWdlaVbzVP083WlPnHiWA" target="_blank">Youtube</a></div>
            </div>
            
            <div className="division">
                <div className='heading'>Nossos serviços</div>
                <div className='interactButton'><a href="mailto:minhastack@gmail.com" target="_blank">Gostaria de uma consultoria, palestra ou curso</a></div>
                <div className='interactButton'><a href="mailto:minhastack@gmail.com" target="_blank">Solicitar implantação de software</a></div>
            </div>
        </div>
    )
}