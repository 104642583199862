import React, { useEffect, useState } from 'react';
const API_ENDPOINTS = "https://ms0001.herokuapp.com";

export default function Home ({
    auth,
    authToken
}) { 
    const [elements, setElements] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [status, setStatus] = useState("")
    const [description, setDescription] = useState("")

    useEffect(() => {
        readData()
    }, [])

    async function readData(){
        const read = await fetch(API_ENDPOINTS+ "/log?reference=TASK&status=PENDING", {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        });

        let returnData = await read.json();
        setElements(returnData.data);
    }

    async function endItem(item){
        try{
            const update = await fetch(API_ENDPOINTS+ "/log/" + item._id, {
                method: 'PUT',
                headers: {
                    'Authorization': authToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...item,
                    status: 'DONE',
                })
            });
            
            alert("Successfully updated")
            readData()
        } catch(e) {
            alert("Error when updating data")
            console.log(e)
        }
    }
    
    async function createItem(){
        const create = await fetch(API_ENDPOINTS+ "/log", {
            method: 'POST',
            headers: {
                'Authorization': authToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                reference: 'TASK',
                status: 'PENDING',
                description
            })
        });
        
        readData()
    }
    
    return (
        <div>
            {dialogOpen ? (
                <div className="container light insight-basic-padding">
                    <div className="insight">Nova tarefa</div>
                    <div className="input">
                        <div className="label">Descrição</div>
                        <input type="text" onChange={(e)=>{setDescription(e.target.value)}} className="textInput" />
                    </div>

                    <br />
                    <div className="button-light sm white" onClick={() => {createItem()}}>Criar</div>
                </div>
            ) : null }
            <div className="container insight-basic-padding">
                <div className="button-light sm" onClick={() => {setDialogOpen(!dialogOpen)}}>Adicionar tarefa</div>
                <div className="insight">Tarefas</div>
            </div>
            <div className="container insight-basic-padding" style={{margin: 0, padding: 10}}>
                { elements.length > 0 ? elements.map(item => (
                    <div style={{
                        display: 'block',
                        background: 'rgb(240, 240, 240)',
                        margin: 10
                    }}>
                        <div style={{padding: 5}}>
                            <div 
                                style={{
                                    display: 'inline-block',
                                    background: 'rgb(230, 230, 230)',
                                    padding: "4px 5px",
                                    margin: 1
                                }} 
                                onClick={() => {endItem(item)}}>Concluir</div>
                            <b>{item._id}</b>
                        </div>
                        <div
                            style={{display: 'inline-block', padding: "0px 11px 11px 11px"}}>
                            {item.description}
                        </div><br />
                    </div>
                )) : (<h2>Nenhuma tarefa pendente</h2>)}
            </div>
        </div>
    )
}